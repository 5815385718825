import React from 'react';
import { Box } from '@chakra-ui/core';

const InstagramIconLink = ({ width, to }: { width: string; to: string }) => {
  return (
    <Box mt={{ base: 4, lg: 0 }} opacity={0.85}>
      <a href={to} target="_blank" rel="noreferrer">
        <svg
          version="1.1"
          id="Logo"
          x="0px"
          y="0px"
          viewBox="0 0 512 512"
          width={width}
        >
          <g>
            <path
              d="M256,49.5c67.3,0,75.2,0.3,101.8,1.5c24.6,1.1,37.9,5.2,46.8,8.7c11.8,4.6,20.2,10,29,18.8s14.3,17.2,18.8,29
		c3.4,8.9,7.6,22.2,8.7,46.8c1.2,26.6,1.5,34.5,1.5,101.8s-0.3,75.2-1.5,101.8c-1.1,24.6-5.2,37.9-8.7,46.8
		c-4.6,11.8-10,20.2-18.8,29s-17.2,14.3-29,18.8c-8.9,3.4-22.2,7.6-46.8,8.7c-26.6,1.2-34.5,1.5-101.8,1.5s-75.2-0.3-101.8-1.5
		c-24.6-1.1-37.9-5.2-46.8-8.7c-11.8-4.6-20.2-10-29-18.8s-14.3-17.2-18.8-29c-3.4-8.9-7.6-22.2-8.7-46.8
		c-1.2-26.6-1.5-34.5-1.5-101.8s0.3-75.2,1.5-101.8c1.1-24.6,5.2-37.9,8.7-46.8c4.6-11.8,10-20.2,18.8-29s17.2-14.3,29-18.8
		c8.9-3.4,22.2-7.6,46.8-8.7C180.8,49.7,188.7,49.5,256,49.5 M256,4.1c-68.4,0-77,0.3-103.9,1.5C125.3,6.8,107,11.1,91,17.3
		c-16.6,6.4-30.6,15.1-44.6,29.1S23.8,74.5,17.3,91c-6.2,16-10.5,34.3-11.7,61.2C4.4,179,4.1,187.6,4.1,256s0.3,77,1.5,103.9
		c1.2,26.8,5.5,45.1,11.7,61.2c6.4,16.6,15.1,30.6,29.1,44.6s28.1,22.6,44.6,29.1c16,6.2,34.3,10.5,61.2,11.7s35.4,1.5,103.9,1.5
		s77-0.3,103.9-1.5c26.8-1.2,45.1-5.5,61.2-11.7c16.6-6.4,30.6-15.1,44.6-29.1s22.6-28.1,29.1-44.6c6.2-16,10.5-34.3,11.7-61.2
		c1.2-26.9,1.5-35.4,1.5-103.9s-0.3-77-1.5-103.9c-1.2-26.8-5.5-45.1-11.7-61.2c-6.4-16.6-15.1-30.6-29.1-44.6s-28.1-22.6-44.6-29.1
		C405.2,11,386.9,6.7,360,5.5C333,4.4,324.4,4.1,256,4.1L256,4.1z"
            />
            <path
              d="M256,126.6c-71.4,0-129.4,57.9-129.4,129.4s58,129.4,129.4,129.4s129.4-58,129.4-129.4S327.4,126.6,256,126.6z M256,340
		c-46.4,0-84-37.6-84-84s37.6-84,84-84s84,37.6,84,84S302.4,340,256,340z"
            />
            <circle cx="390.5" cy="121.5" r="30.2" />
          </g>
        </svg>
      </a>
    </Box>
  );
};

export default InstagramIconLink;
